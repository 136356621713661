import React, { FC } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';

interface NotificationProps {
  title: string;
  description: string;
  type: 'success' | 'error' | 'warning' | 'info';
  onClose: () => void;
  className?: string;
  timeout?: number;
}

const getIconByType = (type: string) => {
  switch (type) {
  case 'success':
    return <CheckCircleIcon className='w-5 h-5 md:w-6 md:h-6 text-green-500' />;
  case 'info':
    return <InformationCircleIcon className='w-5 h-5 md:w-6 md:h-6 text-gray-500' />;
  case 'warning':
    return <ExclamationTriangleIcon className='w-5 h-5 md:w-6 md:h-6 text-orange-500' />;
  case 'error':
    return <ExclamationCircleIcon className='w-5 h-5 md:w-6 md:h-6 text-red-500' />;
  default:
    return null;
  }
};

export const Notification: FC<NotificationProps> = ({
  title, description, type, onClose, className, timeout
}) => {
  const icon = getIconByType(type);

  return (
    <div className={`z-[60] md:min-w-[400px] max-w-xl w-full md:w-fit transition-all
    ${className} ease-out duration-500`}>
      <div className='p-2 md:p-3 bg-background-480 w-full h-fit
      rounded-xl shadow-xl ring-2 ring-background-450/70'>
        <div className='flex flex-row items-center justify-between'>
          <div className='flex flex-row gap-2'>
            <div className='flex flex-row justify-center'>
              {icon}
            </div>
            <h6 className='text-white text-sm md:text-base font-regular'>
              {title}
            </h6>
          </div>
          {!timeout && (
            <button
              className='focus:outline-none hover:bg-background-490 transition-all
              rounded-lg p-1 hover:ring-2 ring-background-450 group hover:shadow-lg ml-5'
              onClick={() => onClose()}
            >
              <XMarkIcon className='w-5 h-5 text-white/80 group-hover:text-white' />
            </button>
          )}
        </div>
        <p className='text-white/50 text-xs md:text-sm font-regular ml-7 md:ml-8'>
          {description}
        </p>
      </div>
    </div>
  );
};

export default Notification;
