import {
  ChevronUpDownIcon,
  ExclamationCircleIcon,
  PlusIcon
} from '@heroicons/react/24/solid';
import React from 'react';
import Button from '../Button';

export const EditorExample = () => {
  return (
    <div className='relative h-fit text-sm md:text-base'>
      <div className='absolute inset-0 rounded-2xl bg-gradient-to-tr
      from-[#fff] via-[#fff] to-[#fff] opacity-10 blur-lg h-full'/>
      <div className='relative rounded-lg bg-background-500 p-2 sm:p-4 w-fit text-white
      shadow-2xl flex flex-col gap-4 max-w-2xl ring-1 ring-gray-500'>
        <div className='absolute -top-px left-20 right-11 h-px bg-gradient-to-r
        from-white/0 via-white to-white/0'/>
        <div className='absolute -bottom-px left-20 right-11 h-px bg-gradient-to-r
        from-white/0 via-white to-white/0'/>
        <div className='flex flex-row items-center group'>
          <div className='flex flex-shrink-0'>
            <PlusIcon className='w-6 h-6 mr-1 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
            <ChevronUpDownIcon className='w-6 h-6 mr-2 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
          </div>
          <textarea className='bg-transparent outline-none text-white
          font-semibold text-4xl resize-none h-12 w-full' value='Heading 1'/>
        </div>
        <div className='flex flex-row items-center group'>
          <div className='flex flex-row flex-shrink-0'>
            <PlusIcon className='w-6 h-6 mr-1 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
            <ChevronUpDownIcon className='w-6 h-6 mr-2 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
          </div>
          <hr className='border-gray-400 border-2 w-full'/>
        </div>
        <div className='flex flex-row items-center group'>
          <div className='flex flex-row flex-shrink-0'>
            <PlusIcon className='w-6 h-6 mr-1 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
            <ChevronUpDownIcon className='w-6 h-6 mr-2 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
          </div>
          <div className='pl-4 border-note-500 border-l-4 flex flex-col w-full'>
            <div className='text-note-500 font-medium flex flex-row
            gap-1 items-center cursor-pointer'>
              <ExclamationCircleIcon className='h-5 w-5' />
              Note
            </div>
            <textarea className='text-white font-regular mt-2 outline-none
            bg-transparent w-full resize-none'
            // eslint-disable-next-line max-len
            value="This is a note block. This block can be change to be a 'warning' or 'important'." />
          </div>
        </div>
        <div className='flex flex-row items-center group'>
          <div className='flex flex-row flex-shrink-0'>
            <PlusIcon className='w-6 h-6 mr-1 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
            <ChevronUpDownIcon className='w-6 h-6 mr-2 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
          </div>
          <div className='w-full md:w-2/4 h-28 bg-background-400 rounded-md flex
          items-center justify-center font-light'>
            An image
          </div>
        </div>
        <div className='flex flex-row items-center group'>
          <div className='flex flex-row flex-shrink-0'>
            <PlusIcon className='w-6 h-6 mr-1 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
            <ChevronUpDownIcon className='w-6 h-6 mr-2 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
          </div>
          <p className='text-white font-regular text-sm'>
            <span className='text-red-500'>Lorem ipsum </span>
            dolor sit <u>amet consectetur</u> adipisicing elit. <s>Maiores impedit </s>
            perferendis suscipit eaque, <span className='bg-yellow'>iste dolor
            cupiditate blanditiis ratione.</span>
          </p>
        </div>
        <div className='flex flex-row items-center group w-full'>
          <div className='flex flex-shrink-0'>
            <PlusIcon className='w-6 h-6 mr-1 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
            <ChevronUpDownIcon className='w-6 h-6 mr-2 text-background-500
            group-hover:text-white transition-all duration-100 cursor-pointer
            hover:bg-background-480 rounded-md'/>
          </div>
          <p className='text-gray-200 font-regular text-sm'>
            Enter text or type '/' for commands
          </p>
        </div>
        <div className='flex flex-row items-center mt-1 justify-evenly
        flex-wrap md:flex-nowrap gap-x-3'>
          <Button className='mt-4 text-gray-1000 w-60'>
            Export to Markdown
          </Button>
          <Button type='secondary' className='mt-4 w-60'>
            Save
          </Button>
        </div>
      </div>

    </div>
  );
};

export default EditorExample;
