import { Block } from '../../../types';

export const handleImage = (block: Block) => {
  let markdownContent = '';

  markdownContent += `<img src="${block.props.url}"
  alt="${block.props.caption}" width="${block.props.width}" />`;

  return markdownContent;
};

export default handleImage;
