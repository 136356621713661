import axios from 'axios';
import { ReqResponse } from '../../types';

export async function getRoles(ids: string[], token: string | null): Promise<ReqResponse> {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://api.coodo.xyz/roles',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios.request(config).then((response) => {
    return response.data.data;
  }).catch((error) => {
    return error.response;
  });

  if (ids.length === 0)
    return response;
  return response.filter((role: { id: string; }) => {
    return ids.includes(role.id);
  });
}

export default getRoles;
