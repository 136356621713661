import { useAuthUser } from 'react-auth-kit';
import { User } from '../types';

export function AuthUser(): User | null {
  const auth = useAuthUser();
  const data = auth();
  let user: User | null = null;

  if (data) {
    user = {
      id: data.id,
      username: data.username,
      public_flags: data.public_flags,
      flags: data.flags,
      locale: data.locale,
      premium_type: data.premium_type,
      tag: data.tag,
      avatarURL: data.avatarURL,
      disabled: data.disabled,
      roles: data.roles,
      lastUpdate: data.lastUpdate,
      email: data.email,
      token: data.token
    };
  }

  return user;
}
