import {
  Theme,
  darkDefaultTheme
} from '@blocknote/react';

export const EditorTheme = {
  ...darkDefaultTheme,
  componentStyles: () => ({
    Menu: {
      boxShadow: 'none',
      border: 'none'
    },
    Toolbar: {
      backgroundColor: '#111214',
      border: '1px solid #1b1c1d',
      padding: '4px 4px',
      borderRadius: 8
    },
    Tooltip: {
      backgroundColor: '#1b1c1d',
      boxShadow: 'none',
      border: 'none'
    },
    Editor: {
      'code': {
        backgroundColor: '#0F1012',
        color: '#F6F6F6',
        padding: '0px 2.72px',
        borderRadius: 3
      }
    }
  }),
  colors: {
    ...darkDefaultTheme.colors,
    editor: {
      background: 'transparent',
      text: '#F6F6F6'
    },
    sideMenu: '#F6F6F6',
    menu: {
      background: '#1b1c1d',
      text: '#F6F6F6'
    }
  },
  borderRadius: 8,
  fontFamily: 'ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", \
  Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", \
  "Segoe UI Symbol"'
} satisfies Theme;

export default EditorTheme;
