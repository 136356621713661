import axios from 'axios';
import { File, ReqResponse } from '../../../types';

export async function updateFile(
  id: string | undefined, newFile: File, token: string | undefined
): Promise<ReqResponse> {
  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/readme/files/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: newFile
  };

  const response = await axios.request(config).then((response) => {
    return response.data;
  }).catch((error) => {
    console.error(error);
    return error.response.data;
  });
  return response;
}

export default updateFile;
