import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Editor, Home, NotFound } from '../pages';
import { Login, Logout } from '../auth';

export const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/editor' element={<Editor />} />
      <Route path='/files/:id' element={<Editor />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};
