import React, { FunctionComponent, useState } from 'react';
import {
  BlockNoteView,
  FormattingToolbarPositioner,
  HyperlinkToolbarPositioner,
  SideMenuPositioner,
  SlashMenuPositioner,
  useBlockNote
} from '@blocknote/react';
import CustomSchema from './CustomSchema';
import SlashMenuItemList from './SlashMenuItemList';
import EditorTheme from './EditorTheme';
import CustomFormattingToolbar from './CustomFormattingToolbar';
import { File, User } from '../../types';
import { createFile, updateFile } from '../../utils/api';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import {
  CloudArrowUpIcon,
  PencilIcon
} from '@heroicons/react/24/outline';

interface EditorViewProps {
  file: File;
  user: User | null;
  setFile: (file: File) => void;
}

const EditorView: FunctionComponent<EditorViewProps> = ({
  file, user, setFile
}) => {
  const [savedState, setSavedState] = useState<'saved' | 'saving' | 'notsaved'>(
    file.id ? 'saved' : 'notsaved'
  );
  const navigate = useNavigate();

  console.info('file', file.id);

  const editor = useBlockNote({
    initialContent: file.content ? JSON.parse(file.content as string) : undefined,
    onEditorContentChange: () => {
      setSavedState('notsaved');
      localStorage.setItem(
        'editorContent',
        JSON.stringify(editor.topLevelBlocks)
      );
    },
    blockSchema: CustomSchema,
    slashMenuItems: SlashMenuItemList
  });

  const handleSave = async () => {
    setSavedState('saving');
    if (file?.id) {
      const newFile: File = {
        content: editor.topLevelBlocks,
        title: file.title
      };
      await updateFile(file.id, newFile, user?.token);
    } else {
      const newFile: File = {
        title: file.title,
        content: editor.topLevelBlocks,
        owner: {
          id: user?.id,
          username: user?.username
        }
      };
      const res = await createFile(newFile, user?.token);
      if (res.data.id) {
        navigate(`/files/${res.data.id}`);
        localStorage.removeItem('editorContent');
      }
    }
    setSavedState('saved');
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSavedState('notsaved');
    const newFile: File = {
      ...file,
      title: e.target.value
    };
    setFile(newFile);
  };

  return (
    <>
      <div className='flex flex-row px-14 py-4 gap-2 justify-end relative'>
        <Button onClick={() => handleSave()} disabled={savedState === 'saved'}
          loading={savedState === 'saving'} className='w-28'>
          <CloudArrowUpIcon className='w-5 h-5 mr-1' />
          Save
        </Button>
      </div>
      <div className='flex flex-row px-14 w-fit'>
        <form>
          <div className='relative group w-fit'>
            <div className='absolute inset-y-0 -end-6 flex items-center
            pointer-events-none group-hover:opacity-100 opacity-0 transition-all'>
              <PencilIcon className='w-5 h-5 text-white/80' aria-hidden='true'/>
            </div>
            <input type='text' id='title' value={file.title} className='block w-full py-4
            text-lg text-white/80 bg-transparent outline-none'
            placeholder='Document title' onChange={(e) => handleChangeTitle(e)} />
          </div>
        </form>
      </div>
      <BlockNoteView editor={editor} theme={EditorTheme}>
        <FormattingToolbarPositioner
          editor={editor}
          formattingToolbar={CustomFormattingToolbar}
        />
        <HyperlinkToolbarPositioner editor={editor} />
        <SlashMenuPositioner editor={editor} />
        <SideMenuPositioner editor={editor} />
      </BlockNoteView>
    </>
  );
};

export default EditorView;
