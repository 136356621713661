import {
  ColorStyleButton,
  CreateLinkButton,
  NestBlockButton,
  TextAlignButton,
  ToggledStyleButton,
  Toolbar,
  UnnestBlockButton
} from '@blocknote/react';

const CustomFormattingToolbar = (props: { editor: any }) => {
  return (
    <Toolbar>
      {/**
        <BlockTypeDropdown editor={props.editor} />
        <div className='w-2' />
      */}
      <ToggledStyleButton editor={props.editor} toggledStyle={'bold'} />
      <ToggledStyleButton editor={props.editor} toggledStyle={'italic'} />
      <ToggledStyleButton editor={props.editor} toggledStyle={'underline'} />
      <ToggledStyleButton editor={props.editor} toggledStyle={'strike'} />
      <div className='w-2' />
      <TextAlignButton editor={props.editor} textAlignment='left' />
      <TextAlignButton editor={props.editor} textAlignment='center' />
      <TextAlignButton editor={props.editor} textAlignment='right' />
      <div className='w-2' />
      <ColorStyleButton editor={props.editor} />
      <CreateLinkButton editor={props.editor} />
      <ToggledStyleButton editor={props.editor} toggledStyle={'code'} />
      <div className='w-2' />
      <UnnestBlockButton editor={props.editor} />
      <NestBlockButton editor={props.editor} />
    </Toolbar>
  );
};

export default CustomFormattingToolbar;
