import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';

interface ModalProps {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  close?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children, title, subtitle, close
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target))
      close?.();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='fixed top-0 left-0 right-0 bottom-0 bg-gray-1000 bg-opacity-50
      backdrop-filter backdrop-blur-sm z-50'/>
      <div className='fixed left-0 right-0 top-0 z-50 p-4 animate-fade-in transition-all
      duration-300 w-full h-full flex justify-center'>
        <div className='relative w-fit min-w-0 sm:min-w-[600px] max-w-lg
        max-h-full m-auto' ref={ref}>
          <div className='relative p-2 md:p-3 bg-background-480 w-full h-fit rounded-xl
          shadow-xl ring-2 ring-background-450/70'>
            {close && (
              <div className='flex flex-row justify-end items-center w-full h-fit'>
                <button className='focus:outline-none hover:bg-background-490
                transition-all rounded-lg p-1 hover:ring-2 ring-background-450
                group hover:shadow-lg ml-5' onClick={() => close()}>
                  <XMarkIcon className='w-5 h-5 text-white/80 group-hover:text-white' />
                </button>
              </div>
            )}
            <div className='flex flex-col items-center'>
              <h1 className='text-white text-lg md:text-xl font-semiBold'>
                {title}
              </h1>
              <p className='text-white/50 text-base md:text-lg font-regular'>
                {subtitle}
              </p>
              <div className='w-full mt-10'>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
