import React, { FunctionComponent } from 'react';
import { Button } from '../../components';

const EditorViewSkeleton: FunctionComponent = () => {
  return (
    <>
      <div className='flex flex-row px-14 py-4 justify-between'>
        <div className='flex flex-row gap-2'>
          <Button disabled={true} className='w-20'>
            Save
          </Button>
        </div>
      </div>
      <div className='flex flex-col w-full h-full px-14'>
        <div className='bg-background-450 animate-pulse h-8 w-full md:w-8/12
        rounded-lg' />
        <div className='bg-background-450 animate-pulse h-8 w-10/12 md:w-6/12
        rounded-lg mt-4' />
      </div>
    </>
  );
};

export default EditorViewSkeleton;
