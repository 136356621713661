import { Block, Props } from '../../../types';
import {
  handleStyle
} from '../index';

function addPosition(props: Props, start: boolean) {
  if (props.textAlignment === 'center')
    return start ? '<p align="center">' : '</p>';
  if (props.textAlignment === 'right')
    return start ? '<p align="right">' : '</p>';
  return '';
}

export const handleParagraph = (block: Block) => {
  let markdownContent = '';

  markdownContent += addPosition(block.props, true);
  markdownContent += handleStyle(block.content);
  markdownContent += addPosition(block.props, false);

  return markdownContent;
};

export default handleParagraph;
