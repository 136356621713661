import React, { FunctionComponent, ReactNode } from 'react';

interface ButtonProps {
  name?: string;
  type?: 'secondary' | 'transparent' | 'danger';
  href?: string;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  name, type, href, className, children, onClick, disabled, loading
}) => {
  const handleClick = (href: string | undefined) => {
    if (href) {
      window.location.href = href;
    } else if (onClick) {
      onClick();
    }
  };

  let style = '';

  switch (type) {
  case 'secondary':
    style += 'bg-transparent hover:bg-white hover:bg-opacity-10 active:bg-opacity-20 \
    text-white disabled:hover:bg-gray-150 border border-white py-[7px] px-[15px] \
    shadow-lg';
    break;
  case 'transparent':
    style += 'bg-transparent text-white py-2 px-4 hover:text-white/90 \
    active:text-white/80';
    break;
  case 'danger':
    style += 'bg-red-500 hover:bg-red-500/90 active:bg-red-500/60 text-white \
      disabled:bg-red-500/80 py-2 px-4 ring-red-500/20 hover:ring-[3px] \
      disabled:ring-0 shadow-lg';
    break;
  default:
    style += 'bg-white hover:bg-white/90 active:bg-white/60 text-black \
      disabled:bg-white/80 py-2 px-4 ring-white/20 hover:ring-[3px] \
      disabled:ring-0 shadow-lg';
    break;
  }

  return (
    <button className={`items-center
      font-medium h-fit rounded-md flex flex-row justify-center
      disabled:opacity-60 disabled:cursor-not-allowed min-h-[36px] ${style}
      transition duration-200 ease-in-out text-sm ${className}`}
    disabled={disabled || loading || false} onClick={() => handleClick(href)}>
      {loading ? (
        <div role='status'>
          <svg aria-hidden='true' className={`w-4 h-4 animate-spin text-transparent
          ${style === 'danger' ? 'fill-white' : 'fill-background-650'}`}
          viewBox='0 0 100 101' fill='none'
          xmlns='http://www.w3.org/2000/svg'>
            <path d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079
            33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826
            10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698
            1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328
            37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505
            10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766
            65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849
            25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158
            91.5421 39.6781 93.9676 39.0409Z' fill='currentFill'/>
          </svg>
        </div>

      ) : (
        <>
          {name && (
            <div>{name}</div>
          )}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
