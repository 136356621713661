import { Block } from '../../../types';
import {
  handlePosition,
  handleStyle
} from '../index';

export const handleHeading = (block: Block) => {
  let markdownContent = '';

  switch (block.props.level) {
  case 1:
    markdownContent += '# ';
    break;
  case 2:
    markdownContent += '## ';
    break;
  case 3:
    markdownContent += '### ';
    break;
  default:
    break;
  }

  markdownContent += handlePosition(block.props, true);
  markdownContent += handleStyle(block.content);
  markdownContent += handlePosition(block.props, false);

  return markdownContent;
};

export default handleHeading;
