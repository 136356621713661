import axios from 'axios';
import { ReqResponse } from '../../../types';

export async function getFile(
  userId: string | undefined, token: string | undefined
): Promise<ReqResponse> {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/readme/users/${userId}/files`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  const response = await axios.request(config).then((response) => {
    return response.data;
  }).catch((error) => {
    console.error(error);
    return error.response.data;
  });
  return response;
}

export default getFile;
