import axios from 'axios';
import { ReqResponse } from '../../../types';

export async function getUser(id: string | null, token: string | null): Promise<ReqResponse> {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/users/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  const response = await axios.request(config).then((response) => {
    return response;
  }).catch((error) => {
    console.info(error);
    return error.response;
  });
  return response;
}

export default getUser;
