import {
  defaultProps
} from '@blocknote/core';

import {
  createReactBlockSpec
} from '@blocknote/react';

const HrBlock = createReactBlockSpec({
  type: 'hr',
  propSchema: {
    ...defaultProps
  },
  containsInlineContent: false,
  render: () => (
    <div className='w-full h-1 bg-gray-400 my-6' />
  )
});

export default HrBlock;
