import {
  BlockSpec,
  defaultProps
} from '@blocknote/core';

import {
  InlineContent,
  createReactBlockSpec
} from '@blocknote/react';
import {
  ChatBubbleLeftIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { useState } from 'react';

interface colors {
  [key: string]: {
    text: string;
    border: string;
  }
}

const colors: colors = {
  'note': {
    'text': 'text-note-500',
    'border': 'border-note-500'
  },
  'warning': {
    'text': 'text-warning-500',
    'border': 'border-warning-500'
  },
  'important': {
    'text': 'text-important-500',
    'border': 'border-important-500'
  }
};

const Alert = (block: any) => {
  const [type, setType] = useState(block.props.type);
  const color = colors[type];

  return (
    <div className={`pl-4 ${color.border} border-l-4 flex flex-col`}>
      <button className={`${color.text} font-medium flex flex-row
      gap-1 items-center`} onClick={() => {
        const nextType = type === 'note' ? 'warning' :
          type === 'warning' ? 'important' : 'note';

        setType(nextType);
        block.props.type = nextType;
      }}>
        {type === 'note' && <ExclamationCircleIcon className='h-5 w-5' />}
        {type === 'warning' && <ExclamationTriangleIcon className='h-5 w-5' />}
        {type === 'important' && <ChatBubbleLeftIcon className='h-5 w-5' />}
        {type[0].toUpperCase() + type.slice(1)}
      </button>
      <InlineContent />
    </div>
  );
};

const AlertBlock = createReactBlockSpec({
  type: 'alert',
  propSchema: {
    ...defaultProps,
    type: {
      default: 'note',
      values: ['note', 'warning', 'important'],
      type: 'string'
    }
  },
  containsInlineContent: true,
  render: ({ block }) => <Alert {...block} />
});

export default AlertBlock;
