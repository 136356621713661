import { Item } from '../../../types';

function addStyle(item: Item, start: boolean) {
  if (!item.styles)
    return '';
  if (item.styles.bold)
    return start ? '<b>' : '</b>';
  if (item.styles.italic)
    return start ? '<i>' : '</i>';
  if (item.styles.underline)
    return start ? '<u>' : '</u>';
  if (item.styles.strike)
    return start ? '<s>' : '</s>';
  if (item.styles.code)
    return start ? '<code>' : '</code>';
  return '';
}

function addBackgroundColor(item: Item, start: boolean) {
  if (!item.styles) return '';
  if (item.styles.backgroundColor)
    return start ?
      `<span style="background-color: ${item.styles.backgroundColor}">` :
      '</span>';
  return '';
}

function addTextColor(item: Item, start: boolean) {
  if (!item.styles) return '';
  if (item.styles.textColor)
    return start ?
      `<span style="color: ${item.styles.textColor}">` : '</span>';
  return '';
}

export const handleStyle = (content: Item[]) => {
  let markdownContent = '';

  for (let i = 0; i < content.length; i++) {
    const item: Item = content[i];

    if (item.type === 'text') {
      markdownContent += addStyle(item, true);
      markdownContent += addBackgroundColor(item, true);
      markdownContent += addTextColor(item, true);
      markdownContent += item.text;
      markdownContent += addTextColor(item, false);
      markdownContent += addBackgroundColor(item, false);
      markdownContent += addStyle(item, false);
      continue;
    }

    if (item.type === 'link' && item.content) {
      markdownContent += `[${handleStyle(item.content)}](${item.href})`;
      continue;
    }
  }

  return markdownContent;
};

export default handleStyle;
