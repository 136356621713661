/* eslint-disable no-unused-vars */
import { ReactNode, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import Notification from './Notification';

export interface NotificationData {
  title: string;
  description: string;
  type: 'success' | 'error' | 'warning' | 'info';
  id?: number;
  className?: string;
  removing?: boolean;
  timeout?: number;
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
export const NotificationContext = createContext((notification: NotificationData) => {});

type NotificationManagerProp = {
  children: ReactNode;
};

export const NotificationManager: React.FC<NotificationManagerProp> = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<NotificationData>>([]);

  const addNotification = (notification: NotificationData) => {
    const notificationWithTime = {
      ...notification,
      id: Math.floor(Math.random() * 10000000),
      removing: false
    };

    setNotifications([...notifications, notificationWithTime]);

    if (notification.timeout) {
      setTimeout(() => {
        removeNotification(notification);
      }, notification.timeout);
    }
  };

  const removeNotification = (notification: NotificationData) => {
    const newNotification = { ...notification, className: '-translate-x-[420px]' };

    const updatedNotifications = notifications.map((n) => {
      if (n.id === notification.id) return newNotification;
      return n;
    });

    setNotifications(updatedNotifications);

    setTimeout(() => {
      const newNotifications = notifications.filter((n) => n.id !== notification.id);
      setNotifications(newNotifications);
    }, 500);
  };

  return (
    <NotificationContext.Provider value={addNotification}>
      {children}
      <div className='absolute flex flex-col bottom-0 left-0 w-fit h-full
      justify-end p-4 gap-4'>
        {notifications.reverse().map((notification) => (
          <Notification
            key={notification.id}
            onClose={() => removeNotification(notification)}
            className={notification.className}
            {...notification}
          />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

NotificationManager.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotificationManager;
