import { Block } from '../../../types';
import {
  handlePosition,
  handleStyle
} from '../index';

export const handleQuote = (block: Block) => {
  let markdownContent = '';

  markdownContent += '> ';
  markdownContent += handlePosition(block.props, true);
  markdownContent += handleStyle(block.content);
  markdownContent += handlePosition(block.props, true);

  return markdownContent;
};

export default handleQuote;
