import React, { FC, useContext, useEffect } from 'react';
import { Context } from './SidebarContext';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/solid';
import SidebarButton from './SidebarButton';
import {
  ArchiveBoxIcon,
  ClipboardDocumentListIcon,
  DocumentIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import { AuthUser } from '../../auth';
import { File } from '../../types';
import { createFile, getUserFiles } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../index';

export const Sidebar: FC = () => {
  const context = useContext(Context);
  const user = AuthUser();
  const addNotification = useContext(NotificationContext);
  const navigate = useNavigate();
  const [files, setFiles] = React.useState<File[]>([]);
  const [createLoading, setCreateLoading] = React.useState<boolean>(false);

  if (!context) {
    throw new Error('Context must be used within a ContextProvider');
  }
  const { SidebarState, SetSidebarState } = context;

  useEffect(() => {
    const getFiles = async () => {
      const res = await getUserFiles(user?.id, user?.token);
      const files = res?.data;
      setFiles(files);
    };
    getFiles();
  }, []);

  const handleSidebar = () => {
    const state = SidebarState === 0 ? 1 : 0;
    SetSidebarState(state);
  };

  const handleNewNote = async () => {
    setCreateLoading(true);
    const newFile: File = {
      content: [{
        'id':'6ec55d56-068d-4f4f-a801-7f17ae44bd8e','type':'paragraph','props':
        {'textColor':'default','backgroundColor':'default','textAlignment':'left'},
        'content':[],'children':[]
      }],
      owner: {
        id: user?.id,
        username: user?.username
      }
    };
    const res = await createFile(newFile, user?.token);
    setCreateLoading(false);
    if (res.data?.id) {
      navigate(`/files/${res.data.id}`);
      addNotification({
        title: 'Success',
        description: 'File created successfully',
        type: 'success'
      });
    } else {
      console.error('Error creating file');
      addNotification({
        title: 'Error' + (res.errorCode ? ` (${res.errorCode})` : ''),
        description: 'Could not create file',
        type: 'error'
      });
    }
  };

  let className = '';
  if (SidebarState)
    className = '-translate-x-full md:-translate-x-[full]';
  else
    className = 'translate-x-0 md:translate-x-0';

  return (
    <div className='flex w-screen sm:w-auto fixed sm:relative z-50 top-0
    sm:top-auto'>
      <div className={`z-30 absolute h-screen min-h-full overflow-y-auto scrollbar-hide
      w-full sm:w-72 transform lg:!translate-x-0 lg:relative transition-all duration-400
      bg-background-500 ${className} p-3`}>
        <div className='flex flex-row justify-end items-center'>
          <div className='flex flex-row items-center rounded-lg overflow-hidden
          px-3 py-2 text-white/80 w-full transition-all'>
            {user?.avatarURL ? (
              <img src={user.avatarURL} alt='avatar'
                className='h-6 w-6 md:h-7 md:w-7 mr-2 rounded-lg shadow-lg ring-2
                ring-background-480' />
            ) : (
              <div className='h-6 w-6 md:h-7 md:w-7 mr-2 rounded-lg shadow-lg ring-2
              ring-background-480 bg-background-400'/>
            )}
            <div className='text-sm md:text-base font-regular'>
              {user ? user.username : 'Guest'}
            </div>
          </div>
          <button className='focus:outline-none hover:bg-background-480 h-fit
          transition-all rounded-lg p-1 hover:ring-2 ring-background-450 lg:hidden
          group hover:shadow-lg ml-5' onClick={() => handleSidebar()}>
            <ChevronDoubleLeftIcon className='h-5 w-5 text-white/80
            group-hover:text-white' />
          </button>
        </div>
        <ul className='flex flex-col rounded-lg py-4 top-12 gap-2'>
          <SidebarButton icon={<ArchiveBoxIcon className='h-4 w-4' />}
            title='All Notes' />
          <SidebarButton icon={<ClipboardDocumentListIcon className='h-4 w-4' />}
            title='Templates' />
        </ul>
        <div className='flex flex-col rounded-lg py-4 top-12 gap-2'>
          <div className='text-white text-sm ml-3 py-1'>
            My Notes
          </div>
          {files && files.map((file) => (
            <SidebarButton
              key={file.id}
              icon={<DocumentIcon className='h-4 w-4' />}
              title={file.title}
              onClick={() => {
                navigate(`/files/${file.id}`);
              }}
            />
          ))}
          <SidebarButton
            onClick={() => handleNewNote()}
            icon={<PlusIcon className='h-4 w-4' />}
            title={'New Note'}
            loading={createLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
