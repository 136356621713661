import React, { FunctionComponent } from 'react';
import { Header, Footer, Sidebar } from '../components';
import PropTypes from 'prop-types';

interface LayoutProps {
  children: React.ReactNode;
  sidebar?: boolean;
  header?: boolean;
  footer?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({
  children, sidebar, header, footer
}) => {
  return (
    <div className='bg-background-500 h-fit min-h-screen flex flex-col'>
      {header && (
        <Header />
      )}
      <div className='flex flex-row flex-1 h-full relative'>
        {sidebar && (
          <Sidebar />
        )}
        <div className='flex flex-col pb-3 px-3 flex-1'>
          {children}
        </div>
      </div>
      {footer && (
        <Footer />
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
