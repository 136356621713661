import { Block } from '../../../types';

export const handleCode = (block: Block) => {
  let markdownContent = '';

  markdownContent += '```' + block.props.language + '\n';
  for (let i = 0; i < block.content.length; i++) {
    markdownContent += block.content[i].text + '\n';
  }
  markdownContent += '```\n';

  return markdownContent;
};

export default handleCode;
