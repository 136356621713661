import { Block } from '../../types';
import {
  handleAlert,
  handleCode,
  handleHeading,
  handleHr,
  handleImage,
  handleParagraph,
  handleQuote
} from './index';

export const exportToMarkdown = (content: string | null) => {
  console.info('content', content);
  if (!content) return '';
  const jsonContent = JSON.parse(content);
  let markdownContent = '';
  console.info('jsonContent', jsonContent);

  for (let i = 0; i < jsonContent.length; i++) {
    const block: Block = jsonContent[i];

    if (block.type === 'heading')
      markdownContent += handleHeading(block);
    if (block.type === 'paragraph')
      markdownContent += handleParagraph(block);
    if (block.type === 'bulletListItem')
      markdownContent += `- ${handleParagraph(block)}`;
    if (block.type === 'numberedListItem')
      markdownContent += `1. ${handleParagraph(block)}`;
    if (block.type === 'image')
      markdownContent += handleImage(block);
    if (block.type === 'quote')
      markdownContent += handleQuote(block);
    if (block.type === 'hr')
      markdownContent += handleHr();
    if (block.type === 'codeBlock')
      markdownContent += handleCode(block);
    if (block.type === 'alert')
      markdownContent += handleAlert(block);
    if (block.children && block.children.length > 0) {
      markdownContent += '\n\n';
      markdownContent += exportToMarkdown(JSON.stringify(block.children));
    }
    console.info(block);

    markdownContent += '\n\n';
  }
  console.info('markdownContent', markdownContent);
  return markdownContent;
};

export default exportToMarkdown;
