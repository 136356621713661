import React, { useEffect, useState } from 'react';
import { Layout } from './';
import { Button } from '../components';
import {
  ArrowUpOnSquareStackIcon,
  CommandLineIcon,
  ComputerDesktopIcon,
  RocketLaunchIcon,
  SignalIcon
} from '@heroicons/react/24/solid';
import GithubContributor from '../types/GithubContributor';
import blury1 from '../assets/blurry1.svg';
import blury2 from '../assets/blurry2.svg';
import blury3 from '../assets/blurry3.svg';
import { EditorExample, EditorResultExample } from '../components/Home';

export const Home = () => {
  const [ contributors, setContributors ] = useState<GithubContributor[]>([]);

  useEffect(() => {
    fetch('https://api.github.com/repos/Coodo-xyz/Readme-Creator/contributors')
      .then(res => res.json())
      .catch(() => {
        return [];
      })
      .then(data => {
        setContributors(data);
      });
  }, []);

  return (
    <Layout>
      <section className='flex flex-col justify-center w-full py-64 px-5 lg:px-28 isolate
      relative'>
        <div className='absolute inset-x-0 top-28 md:-top-20 -z-10 transform-gpu
        overflow-hidden -left-2/3' aria-hidden='true'>
          <img src={blury1} alt='blob'
            className='relative left-[calc(50%-11rem)] aspect-[1155/678]
            w-[36.125rem] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem] animate-blob'/>
        </div>
        <div className='absolute inset-x-0 -z-10 transform-gpu overflow-hidden top-64
        hidden md:block' aria-hidden='true'>
          <img src={blury2} alt='blob'
            className='relative aspect-[1155/678] w-[36.125rem] md:translate-x-1/4
            sm:left-[calc(50%-30rem)] sm:w-[72.1875rem] animate-blob delay-300'/>
        </div>
        <div className='max-w-2xl mx-auto text-center flex flex-col items-center'>
          <h1 className='text-white font-bold text-4xl sm:text-6xl max-w-xl'>
            The easiest way to create a README
          </h1>
          <p className='mt-6 text-lg text-gray-100 font-regular'>
            This is a free and open source project that allows you to create
            a README for your project in a matter of seconds.
          </p>
          <div className='flex flex-row justify-center mt-6 gap-6'>
            <Button href='/editor'>
              Open Editor <RocketLaunchIcon className='w-5 h-5 ml-2'/>
            </Button>
            <Button type='secondary'>
              Learn More <span aria-hidden='true' className='ml-2'>&rarr;</span>
            </Button>
          </div>
        </div>
      </section>
      <section className='flex flex-col justify-center w-full bg-background-500
      px-5 lg:px-28 py-10 mb-20 relative overflow-hidden isolate'>
        <div className='absolute top-px -z-10 h-[64rem] w-[64rem]
        [mask-image:radial-gradient(closest-side,white,transparent)]
        left-1/2 ml-0 -translate-x-1/2 translate-y-0
        bg-white rounded-full blur-3xl opacity-10'/>
        <h2 className='text-white font-bold text-3xl sm:text-5xl text-center'>
          They helped create this project
        </h2>
        <div className='flex flex-row flex-wrap justify-center gap-3 mt-12'>
          {contributors.length > 0 && contributors.map((contributor, index) => {
            return (
              <div key={index} className='flex flex-col items-center cursor-pointer group'
                onClick={() => {
                  window.open(contributor.html_url, '_blank');
                }}>
                <img src={contributor.avatar_url} alt='avatar'
                  className='h-12 w-12 rounded-full group-hover:scale-105 transition-all
                  duration-200'/>
              </div>
            );
          })}
        </div>
      </section>
      <section className='flex flex-col w-full px-5 lg:px-28 py-20 gap-40 z-10'>
        <div className='flex flex-row justify-center gap-x-28 gap-y-16 flex-wrap
        xl:flex-nowrap relative'>
          <EditorExample />
          <div className='flex flex-col xl:max-w-lg'>
            <p className='text-[#574BFF] font-medium text-lg'>
              Create easily
            </p>
            <h3 className='text-white font-bold text-2xl sm:text-4xl mt-2'>
              A complete editor
            </h3>
            <p className='text-white mt-6 font-regular text-lg'>
              Our intuitive tool lets you write and format your READMEs in just a few
              simple steps, making documentation child's play. Save time and make sure
              you present your projects in a professional manner.
            </p>
            <div className='flex flex-col'>
              <p className='text-gray-100 font-regular mt-12'>
                <ComputerDesktopIcon
                  className='w-6 h-6 mr-2 text-[#574BFF] inline-block'/>
                <span className='font-semiBold text-white'>Like Notion: </span>
                Use a fast, easy-to-use editor similar to Notion that lets you add Titles,
                Subtitles, Alerts, CodeBlock, separator lines, paragraphs and more.
              </p>
              <p className='text-gray-100 font-regular mt-12'>
                <SignalIcon className='w-6 h-6 mr-2 text-[#574BFF] inline-block'/>
                <span className='font-semiBold text-white'>Real-time preview: </span>
                See instantly how your README will look when you write it. The editor
                permit you to see the result in real time. No need to know how to use
                Markdown.
              </p>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-center gap-x-28 gap-y-16 flex-wrap
        xl:flex-nowrap relative'>
          <div className='flex flex-col xl:max-w-lg'>
            <p className='text-[#574BFF] font-medium text-lg'>
              Export easily
            </p>
            <h3 className='text-white font-bold text-2xl sm:text-4xl mt-2'>
              Can be used anywhere
            </h3>
            <p className='text-white mt-6 font-regular text-lg'>
              Our tool is designed to be used anywhere. You can configure the tool
              according to the platform where Markdown will be used.
            </p>
            <div className='flex flex-col'>
              <p className='text-gray-100 font-regular mt-12'>
                <CommandLineIcon className='w-6 h-6 mr-2 text-[#574BFF] inline-block'/>
                <span className='font-semiBold text-white'>Easy integration: </span>
                Copy the generated Markdown code and paste it into your project's
                README.md file. It's that simple.
              </p>
              <p className='text-gray-100 font-regular mt-12'>
                <ArrowUpOnSquareStackIcon
                  className='w-6 h-6 mr-2 text-[#574BFF] inline-block'/>
                <span className='font-semiBold text-white'>Export to Markdown: </span>
                Download your README in Markdown format once you've finished, for easy
                integration into your project. Get your Markdown for different platforms
                like GitHub or Discord.
              </p>
            </div>
          </div>
          <EditorResultExample />
        </div>
      </section>
      <section className='flex flex-col w-full px-5 lg:px-28 py-20 relative isolate'>
        <div className='absolute inset-x-0 -z-10 transform-gpu md:-top-1/2'
          aria-hidden='true'>
          <img src={blury3} alt='blob'
            className='relative left-1/2 ml-0 -translate-x-1/2 aspect-[1155/678]
            w-[36.125rem] md:-translate-x-2/4
            sm:w-[72.1875rem] animate-blob'/>
        </div>
        <div className='bg-background-500 rounded-3xl p-10 flex flex-col
        backdrop-blur-3xl bg-opacity-60 shadow-2xl'>
          <h3 className='text-white font-bold text-2xl sm:text-4xl'>
            Ready to start?
          </h3>
          <p className='text-white mt-6 font-regular text-lg'>
            BlockNote is a free and open source project. You can contribute to the
            project on GitHub.
          </p>
          <div className='flex flex-row mt-6 gap-x-6 gap-y-3 flex-wrap md:flex-nowrap'>
            <Button href='/editor'>
              Open Editor <RocketLaunchIcon className='w-5 h-5 ml-2'/>
            </Button>
            <Button type='secondary' onClick={() => {
              window.open('https://github.com/Coodo-xyz/Readme-Creator', '_blank');
            }}>
              Github repository <span aria-hidden='true' className='ml-2'>&rarr;</span>
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};
