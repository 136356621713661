import React from 'react';
import { AuthUser } from '../auth';
import Button from './Button';

export const Header = () => {
  const user = AuthUser();

  const env = {
    clientId: '823271140410916986',
    redirectUri: 'https://api.coodo.xyz/authorize/callback/readme',
    scopes: [ 'identify', 'guilds', 'guilds.members.read', 'email' ]
  };

  const authorizeUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${
    env.clientId
  }&redirect_uri=${
    encodeURIComponent(env.redirectUri)
  }&response_type=code&scope=${env.scopes.join('%20')}`;

  const handleLogoClick = () => {
    window.location.href = '/';
  };
  return (
    <header className='bg-background-500 py-4 lg:px-28 px-5 flex flex-row
    justify-between items-center z-50 backdrop-blur bg-opacity-80'>
      <div className='bg-background-450 h-10 w-10 rounded-full flex items-center
      cursor-pointer'
      onClick={() => handleLogoClick()} />
      <Button type='transparent' onClick={() => {
        window.location.href = authorizeUrl;
      }} href={authorizeUrl}>
        Log in <span aria-hidden="true" className='ml-2'>&rarr;</span>
      </Button>
    </header>
  );
};
