import React from 'react';

const currentYear = new Date().getFullYear();

export const Footer = () => {
  const links = [
    {
      name: 'Github',
      href: 'https://github.com/Coodo-xyz/Readme-Creator'
    },
    {
      name: 'Contact',
      href: 'mailto:contact@coodo.xyz'
    },
    {
      name: 'Status',
      href: 'http://status.coodo.xyz/'
    }
  ];

  return (
    <footer className='w-full bg-background-500 bottom-0 z-40 border-t-2
    sm:border-t-0 border-gray-500 flex flex-col justify-center py-11 gap-5'>
      <div className='flex flex-row justify-center gap-4'>
        {links.map((link, index) => (
          <a href={link.href} key={index} target='_blank'
            className='text-gray-100 font-medium text-sm hover:text-white
            transition-all duration-200'>
            {link.name}
          </a>
        ))}
      </div>
      <p className='text-gray-100 text-sm text-center font-light'>
        &copy; {currentYear} Coodo.xyz. All rights reserved.
      </p>
    </footer>
  );
};
