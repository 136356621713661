import {
  defaultProps
} from '@blocknote/core';

import {
  InlineContent,
  createReactBlockSpec
} from '@blocknote/react';

const CodeBlock = createReactBlockSpec({
  type: 'codeBlock',
  propSchema: {
    ...defaultProps,
    language: {
      type: 'string',
      default: 'js'
    }
  },
  containsInlineContent: true,
  render: () => {
    return (
      <pre className='bg-background-500 rounded-md p-4 w-full inline-block'>
        <InlineContent />
      </pre>
    );
  }
});

export default CodeBlock;
