import axios from 'axios';
import { ReqResponse } from '../../../types';

export async function deleteFile(
  id: string | undefined | null, token: string | undefined
): Promise<ReqResponse> {
  const config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/readme/files/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  const response = await axios.request(config).then((response) => {
    return response.data;
  }).catch((error) => {
    console.error(error);
    return error.response.data;
  });
  return response;
}

export default deleteFile;
