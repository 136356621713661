import {
  BlockSchema,
  defaultBlockSchema
} from '@blocknote/core';
import QuoteBlock from './Components/QuoteBlock';
import HrBlock from './Components/HrBlock';
import CodeBlock from './Components/CodeBlock';
import AlertBlock from './Components/AlertBlock';

export const CustomSchema = {
  ...defaultBlockSchema,
  quote: QuoteBlock,
  hr: HrBlock,
  codeBlock: CodeBlock,
  alert: AlertBlock
} satisfies BlockSchema;

export default CustomSchema;
