import { useLocation, useNavigate } from 'react-router-dom';
import { useSignIn } from 'react-auth-kit';
import { getRoles, getUser } from '../utils/api';
import Cookies from 'universal-cookie';
import { Layout } from '../pages';
import { Button } from '../components';

export const Login = () => {
  const signIn = useSignIn();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const userToken = searchParams.get('token');
  const userId = searchParams.get('id');
  const cookies = new Cookies();
  const env = {
    clientId: '823271140410916986',
    redirectUri: 'https://api.coodo.xyz/authorize/callback/readme',
    scopes: [ 'identify', 'guilds', 'guilds.members.read', 'email' ]
  };

  const authorizeUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${
    env.clientId
  }&redirect_uri=${
    encodeURIComponent(env.redirectUri)
  }&response_type=code&scope=${env.scopes.join('%20')}`;

  if (!userToken || !userId) return (
    <Layout>
      <Button type='transparent' onClick={() => {
        window.location.href = authorizeUrl;
      }} href={authorizeUrl}>
        Log in <span aria-hidden="true" className='ml-2'>&rarr;</span>
      </Button>
    </Layout>
  );

  const authUser = async () => {
    const result = await getUser(userId, userToken);
    const user = result?.data?.data;
    user.token = userToken;

    if (user?.roles?.length > 0)
      user.roles = await getRoles(user.roles, userToken);
    if (!user) {
      navigate('/');
      return null;
    }

    if(signIn({
      token: userToken,
      expiresIn: 43200,
      tokenType: 'Bearer',
      authState: user
    })) {
      const userLocale = user?.locale.split('-')[0] || user?.locale || 'en';
      if (userLocale) {
        cookies.set('language', userLocale, { path: '/' });
      }
      console.info('signIn success');
      navigate('/');
    } else {
      console.error('signIn failed');
      navigate('/');
    }
  };
  authUser();

  return (
    <Layout>
      <div className='flex flex-col justify-center items-center h-screen w-screen'>
        <div className='flex flex-col justify-center items-center'>
          <div className='flex flex-row justify-center items-center'>
            <div className='animate-spin rounded-full h-32 w-32 border-t-2
            border-b-2 border-background-450'></div>
          </div>
          <div className='text-white/80 text-lg font-regular mt-5'>
            Authenticating...
          </div>
        </div>
      </div>
    </Layout>
  );
};
