import React from 'react';
import { Router } from './router/router';
import { NotificationManager } from './components';

const App: React.FC = () => {
  return (
    <NotificationManager>
      <Router />
    </NotificationManager>
  );
};

export default App;
