import { Props } from '../../../types';

export const handlePosition = (props: Props, start: boolean) => {
  if (props.textAlignment === 'center')
    return start ? '<p align="center">' : '</p>';
  if (props.textAlignment === 'right')
    return start ? '<p align="right">' : '</p>';
  return '';
};

export default handlePosition;
