import {
  ReactSlashMenuItem,
  getDefaultReactSlashMenuItems
} from '@blocknote/react';
import {
  ChatBubbleBottomCenterTextIcon,
  CodeBracketIcon,
  ExclamationTriangleIcon,
  MinusIcon
} from '@heroicons/react/24/solid';
import {
  CustomSchema
} from './CustomSchema';

const insertQuoteBlock: ReactSlashMenuItem<typeof CustomSchema> = {
  name: 'Quote',
  execute: (editor) => {
    editor.insertBlocks(
      [
        {
          type: 'quote'
        }
      ],
      editor.getTextCursorPosition().block,
      'before'
    );
  },
  aliases: ['quote', 'codeblock'],
  group: 'Basic blocks',
  icon: <ChatBubbleBottomCenterTextIcon className='w-4 h-4' />,
  hint: 'Used to highlight text from another source'
};

const insertHrBlock: ReactSlashMenuItem<typeof CustomSchema> = {
  name: 'Horizontal rule',
  execute: (editor) => {
    editor.insertBlocks(
      [
        {
          type: 'hr'
        }
      ],
      editor.getTextCursorPosition().block,
      'before'
    );
  },
  aliases: ['hr', 'horizontalrule'],
  group: 'Basic blocks',
  icon: <MinusIcon className='w-4 h-4' />,
  hint: 'Used to separate sections of a document'
};

const insertCodeBlock: ReactSlashMenuItem<typeof CustomSchema> = {
  name: 'Code',
  execute: (editor) => {
    editor.insertBlocks(
      [
        {
          type: 'codeBlock',
          props: {
            language: 'js'
          }
        }
      ],
      editor.getTextCursorPosition().block,
      'before'
    );
  },
  aliases: ['code', 'codeblock'],
  group: 'Basic blocks',
  icon: <CodeBracketIcon className='w-4 h-4' />,
  hint: 'Used to highlight code snippets'
};

const insertAlertBlock: ReactSlashMenuItem<typeof CustomSchema> = {
  name: 'Alert',
  execute: (editor) => {
    editor.insertBlocks(
      [
        {
          type: 'alert',
          props: {
            type: 'note'
          }
        }
      ],
      editor.getTextCursorPosition().block,
      'before'
    );
  },
  aliases: ['alert'],
  group: 'Basic blocks',
  icon: <ExclamationTriangleIcon className='w-4 h-4' />,
  hint: 'Used to highlight important information'
};

export const SlashMenuItemList = [
  ...getDefaultReactSlashMenuItems(CustomSchema),
  insertQuoteBlock,
  insertHrBlock,
  insertCodeBlock,
  insertAlertBlock
];

export default SlashMenuItemList;
