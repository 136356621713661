import React, { FC, Fragment, useContext, useState } from 'react';
import { Context } from './Sidebar/SidebarContext';
import {
  ChevronDoubleRightIcon,
  ClipboardDocumentIcon,
  DocumentArrowDownIcon,
  EllipsisHorizontalIcon,
  LinkIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { File, User } from '../types';
import { Menu, Transition } from '@headlessui/react';
import { exportToMarkdown } from '../utils/Parser';
import { NotificationContext } from './Notification/NotificationManager';
import Modal from './Modal';
import Button from './Button';
import { deleteFile } from '../utils/api';
import { useNavigate } from 'react-router-dom';

interface EditorHeaderProps {
  file: File | null;
  user: User | null;
}

const EditorHeader: FC<EditorHeaderProps> = ({
  file, user
}) => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const addNotification = useContext(NotificationContext);
  const [deleteState, setDeleteState] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  if (!context) {
    throw new Error('Context must be used within a ContextProvider');
  }
  const { SidebarState, SetSidebarState } = context;

  const handleSidebar = () => {
    const state = SidebarState === 0 ? 1 : 0;
    SetSidebarState(state);
  };

  const handleDownload = () => {
    const content = exportToMarkdown(file?.content);
    const blob = new Blob([content], { type: 'text/plain' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'readme.md';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    addNotification({
      title: 'Success',
      description: 'Downloaded successfully',
      type: 'success'
    });
  };

  const handleCopy = () => {
    const content = exportToMarkdown(file?.content);
    navigator.clipboard.writeText(content);
  };

  const handleShare = () => {
    const url = document.location.href;
    navigator.clipboard.writeText(url);
  };

  const actions = [
    {
      title: 'export',
      actions: [
        {
          name: 'Download',
          icon: <DocumentArrowDownIcon className='h-4 w-4 text-white/80 mr-1' />,
          onClick: () => handleDownload()
        },
        {
          name: 'Copy to Clipboard',
          icon: <ClipboardDocumentIcon className='h-4 w-4 text-white/80 mr-1' />,
          onClick: () => handleCopy()
        },
        {
          name: 'Share',
          icon: <LinkIcon className='h-4 w-4 text-white/80 mr-1' />,
          onClick: () => handleShare()
        }
      ]
    },
    {
      title: 'delete',
      actions: [
        {
          name: 'Delete',
          icon: <TrashIcon className='h-4 w-4 text-white/80 mr-1' />,
          onClick: () => setDeleteState(true)
        }
      ]
    }
  ];

  return (
    <div className='flex flex-row items-center w-full p-5 justify-between lg:justify-end'>
      <button className='focus:outline-none hover:bg-background-480 h-fit
      transition-all rounded-lg p-1 hover:ring-2 ring-background-450 lg:hidden
      group hover:shadow-lg' onClick={() => handleSidebar()}>
        <ChevronDoubleRightIcon className='h-5 w-5 text-white/80
        group-hover:text-white' />
      </button>
      <div className='flex flex-row gap-2 justify-end relative items-center'>
        <Menu as='div' className='relative inline-block text-left'>
          <Menu.Button className='focus:outline-none hover:bg-background-480 h-fit
          transition-all rounded-lg p-1 hover:ring-2 ring-background-450
          group hover:shadow-lg' onClick={() => handleSidebar()}>
            <EllipsisHorizontalIcon className='h-5 w-5 text-white/80
            group-hover:text-white' />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-[60] md:min-w-[200px] max-w-xl
            w-full md:w-fit top-10'>
              <div className='p-2 bg-background-480 h-fit text-white/80
            rounded shadow-xl ring-background-450/70 flex flex-col gap-1'>
                {actions.map((action, index) => (
                  <>
                    {action.actions.map((item, index) => (
                      <Menu.Item key={index}>
                        <button className={`items-center font-regular h-fit rounded-sm
                        flex flex-row transition duration-200 ease-in-out text-sm py-1
                        px-2 hover:bg-background-450/50 hover:text-white
                        active:bg-background-450`}
                        onClick={() => item.onClick()}>
                          {item.icon}
                          {item.name}
                        </button>
                      </Menu.Item>
                    ))}
                    {index !== actions.length - 1 && (
                      <div className='border-b border-background-450/50' />
                    )}
                  </>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {deleteState && (
        <Modal title='Delete File' subtitle='Are you sure you want to delete this file?'
          close={() => setDeleteState(false)}>
          <div className='flex flex-row gap-2 justify-end'>
            <Button onClick={() => setDeleteState(false)} className='w-full'>
              Cancel
            </Button>
            <Button className='w-full' type='danger' onClick={async () => {
              setDeleteLoading(true);
              const res = await deleteFile(file?.id, user?.token);
              if (res.data) {
                addNotification({
                  title: 'Success',
                  description: 'File deleted successfully',
                  type: 'success'
                });
                navigate('/editor');
              } else {
                addNotification({
                  title: 'Error' + (res.errorCode ? ` (${res.errorCode})` : ''),
                  description: 'An error occurred while deleting the file',
                  type: 'error'
                });
              }
              setDeleteLoading(false);
              setDeleteState(false);
            }} loading={deleteLoading}>
              Delete
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EditorHeader;
