import { Block } from '../../../types';
import handlePosition from './handlePosition';
import handleStyle from './handleStyle';

export const handleAlert = (block: Block) => {
  let markdownContent = '';

  markdownContent += handlePosition(block.props, true);

  if (block.props.type === 'note')
    markdownContent += '> [!NOTE]\n';
  if (block.props.type === 'warning')
    markdownContent += '> [!WARNING]\n';
  if (block.props.type === 'important')
    markdownContent += '> [!IMPORTANT]\n';
  markdownContent += '> ' + handleStyle(block.content);
  markdownContent += handlePosition(block.props, false);

  return markdownContent;
};

export default handleAlert;
