import React from 'react';
import Button from '../Button';

export const EditorResultExample = () => {
  return (
    <div className='relative h-fit text-sm md:text-base'>
      <div className='absolute inset-0 rounded-2xl bg-gradient-to-tr
      from-[#fff] via-[#fff] to-[#fff] opacity-10 blur-lg h-full'/>
      <div className='relative rounded-lg bg-background-500 p-2 sm:p-4 w-fit text-white
      shadow-2xl flex flex-col gap-4 max-w-2xl ring-1 ring-gray-500'>
        <div className='absolute -top-px left-20 right-11 h-px bg-gradient-to-r
        from-white/0 via-white to-white/0'/>
        <div className='absolute -bottom-px left-20 right-11 h-px bg-gradient-to-r
        from-white/0 via-white to-white/0'/>
        <p className='text-base font-light'>
          # Heading 1
        </p>
        <p className='text-base font-light'>
          ---
        </p>
        <p className='text-base font-light'>
          &gt; [!NOTE]
          <br/>
          &gt; This is a note block. This block can be change to be a
          'warning' or 'important'.
        </p>
        <p className='text-base font-light'>
          &lt;img src="" alt="" width="512" /&gt;
        </p>
        <p className='text-base font-light'>
          &lt;span style="color: red"&gt;Lorem ipsum&lt;/span&gt; dolor sit &lt;u&gt;amet
          consectetur&lt;/u&gt; adipisicing elit. &lt;s&gt;Maiores impedit
          &lt;/s&gt;perferendis suscipit eaque, &lt;span style="background-color:
          yellow"&gt;iste dolor cupiditate blanditiis ratione.&lt;/span&gt;
        </p>
        <div className='flex flex-row items-center mt-1 justify-evenly
        flex-wrap md:flex-nowrap gap-x-3'>
          <Button className='mt-4 text-gray-1000 w-60'>
            Copy to clipboard
          </Button>
          <Button type='secondary' className='mt-4 w-60'>
            Download as Markdown
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditorResultExample;
