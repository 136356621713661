import React, { useState, ReactNode } from 'react';
import PropTypes from 'prop-types';

type ContextValue = {
  SidebarState: number;
  SetSidebarState: React.Dispatch<React.SetStateAction<number>>;
};

export const Context = React.createContext<ContextValue | undefined>(undefined);

type ContextProviderProps = {
  children: ReactNode;
};

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [SidebarState, SetSidebarState] = useState(1);

  return (
    <Context.Provider value={{ SidebarState, SetSidebarState }}>
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
