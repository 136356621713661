import { Button } from '../components';
import {
  ArrowLeftIcon
} from '@heroicons/react/24/solid';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Layout >
      <div className='mx-auto w-full text-center py-20 flex flex-col
      items-center px-4'>
        <h1 className='text-white font-extraBold text-base md:text-lg
        lg:text-xl mb-6'>
          404
        </h1>
        <h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none
        md:text-5xl lg:text-6xl text-white font-bold'>
          Not Found
        </h1>
        <p className='mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48
        text-gray-100 font-medium md:max-w-5xl'>
          The page you are trying to access does not exist. Please contact the
          support if you think this is a mistake.
        </p>
        <Button className='w-full max-w-sm' onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='w-6 h-6 mr-2'/>
          Go Back
        </Button>
      </div>
    </Layout>
  );
};
