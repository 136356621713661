import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  signOut();
  navigate('/');
  return (
    <div className='flex flex-col justify-center items-center h-screen w-screen'>
      <div className='flex flex-col justify-center items-center'>
        <div className='flex flex-row justify-center items-center'>
          <div className='animate-spin rounded-full h-32 w-32 border-t-2
          border-b-2 border-background-450'></div>
        </div>
        <div className='text-white/80 text-lg font-regular mt-5'>
          Signing out...
        </div>
      </div>
    </div>
  );
};
