import {
  defaultProps
} from '@blocknote/core';

import {
  InlineContent,
  createReactBlockSpec
} from '@blocknote/react';

const QuoteBlock = createReactBlockSpec({
  type: 'quote',
  propSchema: {
    ...defaultProps
  },
  containsInlineContent: true,
  render: () => (
    <div className='pl-4 border-l-4 border-gray-400 text-gray-300'>
      <InlineContent />
    </div>
  )
});

export default QuoteBlock;
