import '@blocknote/core/style.css';
import Layout from './Layout';
import { EditorHeader } from '../components';
import {
  EditorView,
  EditorViewSkeleton
} from './Editor/index';
import { useEffect, useState } from 'react';
import { getFile } from '../utils/api';
import { AuthUser } from '../auth';
import { File, User } from '../types';
import { useParams } from 'react-router-dom';

const initialContent: string | null = localStorage.getItem('editorContent');

export const Editor = () => {
  const user: User | null = AuthUser();
  const id = useParams();
  const [file, setFile] = useState<File | null>(null);
  console.info('aaa');

  useEffect(() => {
    const fetchFile = async () => {
      const res = await getFile(id.id, user?.token);
      const file = res?.data;
      setFile(file);
    };
    const setDefaultContent = () => {
      const content = initialContent ? initialContent : undefined;
      const file: File = {
        id: null,
        title: 'Untitled',
        content: content,
        owner: {
          id: user?.id,
          username: user?.username
        }
      };
      setFile(file);
    };

    if (file?.id !== id.id)
      setFile(null);
    if (id.id)
      fetchFile();
    else
      setDefaultContent();
  }, [id.id]);

  return (
    <Layout sidebar={true}>
      <EditorHeader file={file} user={user} />
      <div className='flex flex-col w-full bg-background-490 rounded-2xl
      border-background-480 border-2 h-full'>
        <div className='w-full h-full'>
          {file ? (
            <EditorView
              key={file.id}
              file={file}
              user={user}
              setFile={setFile}
            />
          ) : (
            <EditorViewSkeleton />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Editor;
